import BigNumber from 'bignumber.js'
import { BLOCKS_PER_YEAR, CAKE_PER_YEAR } from 'config'
import lpAprs from 'config/constants/lpAprs.json'
import { BIG_ONE, BIG_TEN, BIG_ZERO } from './bigNumber'

/**
 * Get the APR value in %
 * @param stakingTokenPrice Token price in the same quote currency
 * @param rewardTokenPrice Token price in the same quote currency
 * @param totalStaked Total amount of stakingToken in the pool
 * @param tokenPerBlock Amount of new cake allocated to the pool for each new block
 * @returns Null if the APR is NaN or infinite.
 */
export const getPoolApr = (
  stakingTokenPrice: number,
  rewardTokenPrice: number,
  totalStaked: number,
  tokenPerBlock: number,
): number => {
  const totalRewardPricePerYear = new BigNumber(rewardTokenPrice).times(tokenPerBlock).times(BLOCKS_PER_YEAR)
  const totalStakingTokenInPool = new BigNumber(stakingTokenPrice).times(totalStaked)
  const apr = totalRewardPricePerYear.div(totalStakingTokenInPool).times(100)
  return apr.isNaN() || !apr.isFinite() ? null : apr.toNumber()
}

// /**
//  * Get farm APR value in %
//  * @param poolWeight allocationPoint / totalAllocationPoint
//  * @param cakePriceUsd Cake price in USD
//  * @param poolLiquidityUsd Total pool liquidity in USD
//  * @param farmAddress Farm Address
//  * @returns Farm Apr
//  */
//  export const getFarmApr = (
//   poolWeight: BigNumber,
//   cakePriceUsd: BigNumber,
//   poolLiquidityUsd: BigNumber,
//   farmAddress: string,
// ): { cakeRewardsApr: number; lpRewardsApr: number } => {
//   const yearlyCakeRewardAllocation = poolWeight ? poolWeight.times(CAKE_PER_YEAR) : new BigNumber(NaN)
//   const cakeRewardsApr = yearlyCakeRewardAllocation.times(cakePriceUsd).div(poolLiquidityUsd).times(100)
//   let cakeRewardsAprAsNumber = null
//   if (!cakeRewardsApr.isNaN() && cakeRewardsApr.isFinite()) {
//     cakeRewardsAprAsNumber = cakeRewardsApr.toNumber()
//   }
//   const lpRewardsApr = lpAprs[farmAddress?.toLocaleLowerCase()] ?? 0
//   return { cakeRewardsApr: cakeRewardsAprAsNumber, lpRewardsApr }
// }


/**
 */
export const getFarmApr = (
  rewardPreBlock: BigNumber,
  tokenPriceBusd: BigNumber,
  lpPrice: BigNumber
): number => {
  try {
    const yearlyCakeRewardAllocation = rewardPreBlock ? rewardPreBlock.dividedBy(BIG_TEN.pow(18)).times(tokenPriceBusd).times(CAKE_PER_YEAR) : BIG_ZERO
  const cakeRewardsApr =lpPrice.gt(0)? yearlyCakeRewardAllocation.div(lpPrice).times(100):BIG_ZERO
  return cakeRewardsApr.toNumber()
  } catch (error) {
    return 0
  }
  
}

/*
  == APR1
  w3APR: !isRewardPCoin ? 0 :【pCoinRewardPerBlock】x20x60x24x365xpool.allpoint / totalallpoint / lpprice
  项目方APR：【rewardPreBlock】x20x60x24x365 / lpprice
  == 倍数
  isRewardPCoin === true 子项的倍数和
  */
// pcoinrewardperblockx20x60x24x365x(pool.allpoint累加)/totalallpoint/lpprice