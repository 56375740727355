import { retry } from "state/multicall/retry"

// 1 day, 7 days, 30 days, 1 year, 5 years
const DAYS_TO_CALCULATE_AGAINST = [1, 7, 30, 365, 1825]

/**
 *
 * @param principalInUSD - amount user wants to invest in USD
 * @param apr - farm or pool apr as percentage. If its farm APR its only cake rewards APR without LP rewards APR
 * @param earningTokenPrice - price of reward token
 * @param compoundFrequency - how many compounds per 1 day, e.g. 1 = one per day, 0.142857142 - once per week
 * @param performanceFee - performance fee as percentage
 * @returns an array of token values earned as interest, with each element representing interest earned over a different period of time (DAYS_TO_CALCULATE_AGAINST)
 */
export const getInterestBreakdown = ({
  principalInUSD,
  apr,
  earningTokenPrice,
  compoundFrequency = 1,
  performanceFee = 0,
}: {
  principalInUSD: number
  apr: number
  earningTokenPrice: number
  compoundFrequency?: number
  performanceFee?: number
}) => {

  const aprAsDecimal = apr / 100
  const principal = principalInUSD / earningTokenPrice
  return DAYS_TO_CALCULATE_AGAINST.map((days) => {
    const daysAsDecimalOfYear = days / 365
    return aprAsDecimal * principal * daysAsDecimalOfYear
  })
}

/**
 * @param interest how much USD amount you aim to make
 * @param apr APR of farm/pool
 * @param compoundingFrequency how many compounds per 1 day, e.g. 1 = one per day, 0.142857142 - once per week
 * @returns an array of principal values needed to reach target interest, with each element representing principal needed for a different period of time (DAYS_TO_CALCULATE_AGAINST)
 */
export const getPrincipalForInterest = (
  interest: number,
  apr: number,
  compoundingFrequency: number,
  performanceFee = 0,
) => {
  return DAYS_TO_CALCULATE_AGAINST.map((days) => {
    const apyAsDecimal = getApy(apr, compoundingFrequency, days, performanceFee)
    // //console.log('inside', interest, apyAsDecimal)
    // const apyAsBN = new BigNumber(apyAsDecimal).decimalPlaces(6, BigNumber.ROUND_DOWN).toNumber()
    return parseFloat((interest / apyAsDecimal).toFixed(2))
  })
}

export const getLPFarmApy = ({
  principalInUSD,
  apr,
  earningTokenPrice
}: {
  principalInUSD: number
  apr: number
  earningTokenPrice: number
}) => {
  return principalInUSD * apr / 100;
}



/**
 * Given APR returns APY
 * @param apr APR as percentage
 * @param compoundFrequency how many compounds per day
 * @param days if other than 365 adjusts (A)PY for period less than a year
 * @param performanceFee performance fee as percentage
 * @returns APY as decimal
 */
export const getApy = (apr: number, compoundFrequency = 1, days = 365, performanceFee = 0) => {
  const daysAsDecimalOfYear = days / 365
  const aprAsDecimal = apr / 100
  // const timesCompounded = 365 * compoundFrequency
  let apyAsDecimal = aprAsDecimal * daysAsDecimalOfYear
  // if (timesCompounded > 0) {
  //   apyAsDecimal = (1 + aprAsDecimal / timesCompounded) ** (timesCompounded * daysAsDecimalOfYear) - 1
  // }
  // if (performanceFee) {
  //   const performanceFeeAsDecimal = performanceFee / 100
  //   const takenAsPerformanceFee = apyAsDecimal * performanceFeeAsDecimal
  //   apyAsDecimal -= takenAsPerformanceFee
  // }
  return apyAsDecimal
}

export const getRoi = ({ amountEarned, amountInvested }: { amountEarned: number; amountInvested: number }) => {
  if (amountInvested === 0) {
    return 0
  }
  const percentage = (amountEarned / amountInvested) * 100
  return percentage
}
