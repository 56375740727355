import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'

export type CardBodyProps = SpaceProps

const CardBody = styled.div<CardBodyProps>`
  ${space}
  margin-bottom: constant(safe-area-inset-bottom),
`

CardBody.defaultProps = {
  p: '24px',
  pb: '48px',
  mb: 'env(safe - area - inset - bottom)',
}

export default CardBody
