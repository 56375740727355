const variants = {
  warning: {
    background: '#FFB23719',
    borderColor: 'warning',
  },
  danger: {
    background: '#E7BB4B19',
    borderColor: 'failure',
  },
  success: {
    background: 'rgba(49, 208, 170, 0.1)',
    borderColor: 'success',
  },
}

export default variants
